import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import React, { useState, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';

import { API_URL } from '../config';
import { useGetUserAccounts } from '../context/AccountContext/api/getUserAccounts';
import { Account } from '../context/AccountContext/types';
import { useGetUserInfo } from '../context/UserContext/api/getUserInfo';
import { User } from '../context/UserContext/types';
import { usePersistedState } from '../hooks/usePersistedState';
import { main } from '../themes';
import { getSiteContext } from '../utils/siteContext';

import { AccountContext } from './AccountContext';
import { TabsContext } from './TabsContext';
import { HasTabAccess, UserContext } from './UserContext';

type AppProviderProps = {
  children: React.ReactNode;
};

const isPublicPage = () => {
  // Public pages should not retrieve data from api
  return window.location.pathname === '/sign-in';
};

export const UIProvider = ({ children }: AppProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User>();
  const [currentTab, setCurrentTab] = usePersistedState('currentTab', 'docs');
  const handleError = useErrorHandler();
  const [currentAccount, setAccount] = useState<Account | undefined>();
  const [accountSlug, setAccountSlug] = usePersistedState('accountSlug', undefined);
  const [accounts, setAccounts] = useState<Account[]>();
  const siteContext = getSiteContext();

  const redirectOnError = (err: any) => {
    if (err.response && [401, 403].includes(err.response.status)) {
      window.location.href = `${API_URL}/iam/login?next=${window.location}`;
    } else {
      handleError(err);
    }
  };

  useGetUserInfo(
    {
      account: currentAccount?.slug,
      environment: siteContext.env,
    },
    {
      onSuccess: (resp: User) => {
        setCurrentUser(resp);
        // Sentry tags
        Sentry.setTag('user.slug', resp.slug);
        Sentry.setTag('account.slug', currentAccount?.slug);
        // Google Analytics properties
        if (window.gtag) {
          window.gtag('config', 'G-X8V16WM99D', {
            user_id: resp.slug,
          });
          window.gtag('set', 'user_properties', {
            user_slug: resp.slug,
            account_slug: currentAccount?.slug,
          });
        }
      },
      onError: redirectOnError,
      enabled: !isPublicPage() && !!accounts,
    }
  );

  useGetUserAccounts({
    onSuccess: (accounts: Account[]) => {
      setAccounts(accounts);
      if (accounts.length > 0) {
        if (accountSlug) {
          const storedAccount = accounts.filter((acc) => acc.slug === accountSlug);
          if (storedAccount.length > 0) {
            setAccount(storedAccount[0]);
          } else {
            setAccount(accounts[0]);
          }
        } else {
          setAccount(accounts[0]);
        }
      }
    },
    onError: redirectOnError,
    enabled: !isPublicPage(),
  });

  useEffect(() => {
    if (currentUser && !HasTabAccess(currentUser, currentTab)) {
      setCurrentTab('docs');
    }
  }, [currentTab, currentUser, setCurrentTab]);

  function setCurrentAccount(account: Account | undefined) {
    setAccount(account);
    setAccountSlug(account?.slug);
  }

  return (
    <ChakraProvider resetCSS theme={main}>
      <AccountContext.Provider value={{ currentAccount, setCurrentAccount, accounts, setAccounts }}>
        <TabsContext.Provider value={{ currentTab, setCurrentTab }}>
          <UserContext.Provider value={{ currentUser, setCurrentUser }}>
            <Router>{children}</Router>
          </UserContext.Provider>
        </TabsContext.Provider>
      </AccountContext.Provider>
    </ChakraProvider>
  );
};
