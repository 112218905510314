import { HStack, Icon, Link, LinkProps, useColorModeValue as mode, Text } from '@chakra-ui/react';
import * as React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

interface NavLinkProps extends LinkProps {
  isActive?: boolean;
  label: string;
  icon: any;
}

export const NavLink = (props: NavLinkProps) => {
  const { icon, isActive, label, href, ...rest } = props;
  return (
    <Link
      display="block"
      py={2}
      px={3}
      borderRadius="md"
      transition="all 0.3s"
      fontWeight="medium"
      lineHeight="1.5rem"
      aria-current={isActive ? 'page' : undefined}
      _hover={{
        bg: mode('gray.100', 'gray.700'),
        color: mode('black', 'white'),
      }}
      _activeLink={{
        bg: mode('blue.500', 'blue.300'),
        color: mode('white', 'black'),
      }}
      as={ReactRouterLink}
      to={href}
      {...rest}
    >
      <HStack spacing={4}>
        <Icon as={icon} boxSize="20px" />
        <Text as="span">{label}</Text>
      </HStack>
    </Link>
  );
};
