import { extendTheme } from '@chakra-ui/react';

export const main = extendTheme({
  colors: {
    red: {
      '50': '#FCEAE8',
      '100': '#F7C4C0',
      '200': '#F29F97',
      '300': '#EC796E',
      '400': '#E75446',
      '500': '#E22E1D',
      '600': '#B52517',
      '700': '#881C11',
      '800': '#5A120C',
      '900': '#2D0906',
    },
    green: {
      '50': '#EFF6EF',
      '100': '#D1E6D3',
      '200': '#B3D6B6',
      '300': '#95C59A',
      '400': '#78B57D',
      '500': '#5AA561',
      '600': '#48844D',
      '700': '#36633A',
      '800': '#244227',
      '900': '#122113',
    },
    orange: {
      '50': '#FEF3E7',
      '100': '#FBDEBB',
      '200': '#F9C990',
      '300': '#F7B464',
      '400': '#F49E39',
      '500': '#F2890D',
      '600': '#C26E0A',
      '700': '#915208',
      '800': '#613705',
      '900': '#301B03',
    },
    blue: {
      '50': '#E9F4FC',
      '100': '#C1DFF6',
      '200': '#99CAEF',
      '300': '#71B6E9',
      '400': '#4AA1E3',
      '500': '#228DDD',
      '600': '#1B70B1',
      '700': '#145485',
      '800': '#0E3858',
      '900': '#071C2C',
    },
    teal: {
      '50': '#EDF8F8',
      '100': '#CCEBEA',
      '200': '#ABDDDD',
      '300': '#8BD0D0',
      '400': '#6AC3C2',
      '500': '#49B6B5',
      '600': '#3A9291',
      '700': '#2C6D6D',
      '800': '#1D4948',
      '900': '#0F2424',
    },
    purple: {
      '50': '#EEE7FE',
      '100': '#D0BBFB',
      '200': '#B190F9',
      '300': '#9365F6',
      '400': '#7539F4',
      '500': '#560EF1',
      '600': '#450BC1',
      '700': '#340891',
      '800': '#220660',
      '900': '#110330',
    },
    pink: {
      '50': '#FAEAEE',
      '100': '#F2C5D0',
      '200': '#E99FB1',
      '300': '#E17A92',
      '400': '#D85474',
      '500': '#D02F55',
      '600': '#A62644',
      '700': '#7D1C33',
      '800': '#531322',
      '900': '#2A0911',
    },
    yellow: {
      '50': '#FEFAE7',
      '100': '#FCF2BB',
      '200': '#FAE98E',
      '300': '#F8E162',
      '400': '#F6D836',
      '500': '#F5D00A',
      '600': '#C4A608',
      '700': '#937D06',
      '800': '#625304',
      '900': '#312A02',
    },
    docs: {
      header: '#00044A',
    },
    load: {
      header: '#1a194d',
    },
    transform: {
      header: '#333333',
    },
    observe: {
      header: '#000000',
    },
    analyze: {
      header: '#509ee2',
    },
    orchestrate: {
      header: '#00044A',
    },
  },
});
